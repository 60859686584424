var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex flex-col gap-4" },
      [
        _c("div", { staticClass: "pb-8" }, [
          _c("div", { staticClass: "flex flex-col gap-2" }, [
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    id: "certified_name",
                    label: _vm.$t("certified.name"),
                    disabled: "",
                  },
                  model: {
                    value: _vm.model.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "name", $$v)
                    },
                    expression: "model.name",
                  },
                }),
                _c("vs-input", {
                  attrs: {
                    id: "status",
                    label: _vm.$t("certified_emit.despatch"),
                    disabled: "",
                  },
                  model: {
                    value: _vm.model.release_st_str,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "release_st_str", $$v)
                    },
                    expression: "model.release_st_str",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    id: "institution",
                    label: _vm.$t("fields.institution"),
                    disabled: "",
                  },
                  model: {
                    value: _vm.model.institution.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.model.institution, "name", $$v)
                    },
                    expression: "model.institution.name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    id: "questionnaire",
                    label: _vm.$t("Questionnaire"),
                    disabled: "",
                  },
                  model: {
                    value: _vm.model.certified_commit.questionnaire_name,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.model.certified_commit,
                        "questionnaire_name",
                        $$v
                      )
                    },
                    expression: "model.certified_commit.questionnaire_name",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "w-full mt-3" }, [
              _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.model.description,
                    expression: "model.description",
                  },
                ],
                staticClass:
                  "simple-content simple-editor simple-editor-disabled",
              }),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "mt-2 w-full" },
          [
            _c("media-list", {
              attrs: {
                medias: _vm.files,
                "show-delete": false,
                "action-on-click": false,
              },
            }),
          ],
          1
        ),
        _c("certified-legend"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }