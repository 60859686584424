<template>
  <div class="vs-row w-full pt-2">
    <grid
        ref="grid-user-emit"
        v-if="service"
        :hide_actions="false"
        :hide_edit="true"
        :hide_delete="true"
        :service="service"
        route_name="certified_emit"
        :route_grid_path="`${id}/grid`"
        :column_formats="{
          created_at: (val) => {
            return $utils.format.date(val)
          },
          dispatched_at: (val) => {
            return $utils.format.date(val)
          },
          status : (val) =>{
            switch (val) {
            case ('EM'):
              return 'Emitido'
            case ('PD'):
              return 'Pendente'
            case ('FA'):
              return 'Falha ao emitir'
            default:
              return ''
            }
          }
        }"
        :css_formats="{
          status: (val) => {
            switch (val) {
            case (null):
              return 'text-warning'
            case ('PD'):
              return 'text-warning'
            case ('EM'):
              return 'text-primary'
            case ('FA'):
              return 'text-danger'
            default:
              return ''
            }
          }
        }"
      >
        <template v-slot:actions="actions">
          <feather-icon
            v-permission="`certified_emit.emit`"
            v-tooltip="$t('certified_emit.certified_despatch')"
            v-if="canDispatchCertifiedUser(actions.row)"
            icon="CheckSquareIcon"
            class="h-5 w-5 mb-1 mr-1 stroke-current text-primary"
            @click="confirmDispatchUser(actions.row)"/>

            <feather-icon
              v-if="canDownloadCertifiedUser(actions.row)"
              v-permission="`certified_emit.download`"
              v-tooltip="$t('baixar')"
              icon="DownloadIcon"
              class="h-5 w-5 mb-1 mr-1 stroke-current text-primary"
              @click="downloadCertifiedUser(actions.row)"/>

              <feather-icon
                v-permission="`certified_emit.sendemail`"
                v-tooltip="$t('certified_emit.certified_send_email')"
                v-if="canSendEmailCertifiedUser(actions.row)"
                icon="SendIcon"
                class="h-5 w-5 mb-1 mr-1 stroke-current text-primary"
                @click="sendEmailCertifiedUser(actions.row)"/>

        </template>
      </grid>
      <div class="w-full flex gap-2 pt-2 justify-end">
        <vs-button
          v-permission="'certified_emit.emit'"
          v-tooltip="$t('certified_emit.certified_despatch_all')"
          @click="confirmDispatchAll"
          icon="send">
          {{ $t('certified_emit.emit') }}
        </vs-button>
        <vs-button
          type="border"
          @click="goBack"
          icon="undo">
          {{ $t('common.back') }}
        </vs-button>
      </div>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { debounce } from 'lodash'

import CertifiedUserEmitService from '@/services/api/CertifiedUserEmitService'
import CertifiedUserService from '@/services/api/CertifiedUserService'
import MediaService from '@/services/api/MediaService'
import FileDownload from 'js-file-download'

export default {
  directives: {

  },
  components: {

  },
  props: {
    id: { //Certified Id
      type: Number,
      default: null
    }
  },
  data: () => ({
    service: null,
    certifiedUserService: null,
    mediaService: null,
  }),
  beforeMount() {
    this.service = CertifiedUserEmitService.build(this.$vs)
    this.certifiedUserService = CertifiedUserService.build(this.$vs)
    this.mediaService = MediaService.build(this.$vs)
    this.updateGrid()

  },
  beforeDestroy() {
    //this.$root.$off('missive-dispatched', this.updateGrid)
  },
  methods: {
    updateGrid() {
      const grid = this.$refs['grid-user-emit']
      if (grid) {
        grid.fetchGridData()
      }
    },
    goBack() {
      this.$router.push({
        name: 'certified-emit-index'
      })
    },
    confirmDispatchAll() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('certified_emit.msg.confirm_despatch_all'),
        accept: this.dispatchCertifieds,
      })
    },
    confirmDispatchUser(certifiedUser) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('certified_emit.msg.confirm_despatch_user', [certifiedUser.user.name, certifiedUser.user.email]),
        accept: () => {
          this.dispatchCertifiedUser(certifiedUser.id)
        }
      })
    },
    dispatchCertifieds() {
      if (!this.canDispatch()) {
        this.notifyWarning(this.$vs, this.$t('certified_emit.msg.send_nothing'), 9000)
        return
      }

      this.$vs.loading()
      this.service.dispatchCertifieds(this.id).then(
        response => {
          this.notifySuccess(this.$vs, this.$t('certified_emit.msg.send_pedding'), 9000)
          this.updateGrid()
        },
        error => {
          this.notifyWarning(this.$vs, this.$t('messages.error'))
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    dispatchCertifiedUser(id) {
      console.log('To dispatch destination', id)
      this.$vs.loading()
      this.service.dispatchCertifiedUser(this.id, id).then(
        response => {
          this.notifySuccess(this.$vs, this.$t('messages.success'))
          this.updateGrid()
        },
        error => {
          this.notifyWarning(this.$vs, this.$t('messages.error'))
          this.updateGrid()
        }
      ).finally(
        () => this.$vs.loading.close()
      )
    },

    canDispatchCertifiedUser(destination) {
      const onError = destination.status === 'FA'
      const onPending = destination.status === 'PD'

      return onError || onPending
    },
    canDispatch() {
      const grid = this.$refs['grid-user-emit']
      if (grid) {
        const data = grid.model ? grid.model.data : null
        if (data && data.length > 0) {
          //TODO - Implement
          //const documentDestination = data[0]
          //const count = documentDestination.document.pending_dispatches_count
          return true//count > 0
        }
        return false
      }
      return false
    },
    canDownloadCertifiedUser(emittedUser) {
      const onEmitted = emittedUser.status === 'EM'
      return onEmitted
    },
    downloadCertifiedUser(certUser) {
      // const id = _.get(media, 'id')
      const fileName = _.get(certUser.file_certified, 'file_name')
      const mimeType = _.get(certUser.file_certified, 'mime_type')

      this.certifiedUserService.downloadCertified(certUser.id).then((data) => {
        this.notifySuccess(this.$vs, 'Iniciando Download...')
        FileDownload(data, fileName, mimeType)
        this.$vs.loading.close()
      }, error => {
        this.notifyError(this.$vs, 'Erro! Não foi possível iniciar o download deste arquivo.')
      })
    },
    canSendEmailCertifiedUser(emittedUser) {
      const onEmitted = emittedUser.status === 'EM'
      return onEmitted
    },
    sendEmailCertifiedUser(emittedUser) {
      console.log('To send mail destination', emittedUser.id)
      this.$vs.loading()
      this.service.sendEmailCertifiedUser(this.id, emittedUser.id).then(
        response => {
          this.notifySuccess(this.$vs, this.$t('messages.success'))
        },
        error => {
          this.notifyWarning(this.$vs, this.$t('messages.error'))
        }
      ).finally(
        () => this.$vs.loading.close()
      )
    },
  },
  computed: {

  }
}
</script>


<style lang="scss" scoped>

</style>
