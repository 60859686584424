<template>
  <div>
      <div class="flex flex-col gap-4">
        <div class="pb-8">
          <div class="flex flex-col gap-2">
            <div class="w-full">
              <vs-input
                id="certified_name"
                :label="$t('certified.name')"
                v-model="model.name"
                disabled
                class="w-full"/>
                <vs-input
                  id="status"
                  :label="$t('certified_emit.despatch')"
                  v-model="model.release_st_str"
                  disabled/>
            </div>
            <div class="w-full">
              <vs-input
                id="institution"
                :label="$t('fields.institution')"
                v-model="model.institution.name"
                disabled
                class="w-full"/>
            </div>

            <div class="w-full">
              <vs-input
                id="questionnaire"
                :label="$t('Questionnaire')"
                v-model="model.certified_commit.questionnaire_name"
                disabled
                class="w-full"/>
            </div>
            <div class="w-full mt-3">
              <div class="simple-content simple-editor simple-editor-disabled" v-html-safe="model.description"/>
            </div>
          </div>
        </div>
        <div class="mt-2 w-full">
          <media-list :medias="files"
                      :show-delete="false"
                      :action-on-click="false" />
        </div>
        <certified-legend></certified-legend>
      </div>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'

import CertifiedService from '@/services/api/CertifiedService'
import MediaList from '@/components/media/MediaList.vue'

import CertifiedLegend from './CertifiedLegend.vue'

export default {
  directives: {

  },
  components: {
    MediaList,
    CertifiedLegend
  },
  props: {
    id: {
      default: null,
      type: Number
    },
    model: {
      type: Object
    }
  },

  data: () => ({
    service: null,
    model: {
      id: null,
      name : '',
      description: ''
    },
    files: [],
  }),
  computed: {

  },
  beforeMount() {
    this.service = CertifiedService.build(this.$vs)
  },
  mounted() {
    if(this.id){
      this.loadData(this.id)
    }
  },
  methods: {
    loadData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        response => {
          this.model = response
          this.files = response.files
        },
        error => {

        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/simple-content.scss';
.vs-list--header{
  display: inline !important;
}

</style>
