<template>
  <div>
    <vs-tabs>
      <vs-tab :label="$t('certified_emit.certified')">
        <vx-card class="mb-4">
          <div class="flex flex-col gap-4">
              <certified-detail :id="id"/>
          </div>
          <div class="w-full flex gap-2 pt-3 justify-end">
            <vs-button
              type="border"
              @click="goBack"
              icon="undo">
              {{ $t('common.back') }}
            </vs-button>
          </div>
        </vx-card>
      </vs-tab>
      <vs-tab :label="$t('certified_emit.despatch')">
        <vx-card>
          <certified-emit-form :id="id"/>
        </vx-card>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import CertifiedUserEmitService from '@/services/api/CertifiedUserEmitService'
import CertifiedDetail from './CertifiedDetail.vue'
import CertifiedEmitForm from './CertifiedEmitForm.vue'

export default {
  components: {
    CertifiedDetail,
    CertifiedEmitForm
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
  }),

  computed: {

  },
  mounted() {

  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'certified-emit-index'
      })
    },
  },
  beforeMount() {
    this.service = CertifiedUserEmitService.build(this.$vs)
  }
}
</script>

<style>

</style>
