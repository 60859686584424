var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-tabs",
        [
          _c(
            "vs-tab",
            { attrs: { label: _vm.$t("certified_emit.certified") } },
            [
              _c("vx-card", { staticClass: "mb-4" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-col gap-4" },
                  [_c("certified-detail", { attrs: { id: _vm.id } })],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full flex gap-2 pt-3 justify-end" },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { type: "border", icon: "undo" },
                        on: { click: _vm.goBack },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "vs-tab",
            { attrs: { label: _vm.$t("certified_emit.despatch") } },
            [
              _c(
                "vx-card",
                [_c("certified-emit-form", { attrs: { id: _vm.id } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }