var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vs-row w-full pt-2" },
    [
      _vm.service
        ? _c("grid", {
            ref: "grid-user-emit",
            attrs: {
              hide_actions: false,
              hide_edit: true,
              hide_delete: true,
              service: _vm.service,
              route_name: "certified_emit",
              route_grid_path: _vm.id + "/grid",
              column_formats: {
                created_at: function (val) {
                  return _vm.$utils.format.date(val)
                },
                dispatched_at: function (val) {
                  return _vm.$utils.format.date(val)
                },
                status: function (val) {
                  switch (val) {
                    case "EM":
                      return "Emitido"
                    case "PD":
                      return "Pendente"
                    case "FA":
                      return "Falha ao emitir"
                    default:
                      return ""
                  }
                },
              },
              css_formats: {
                status: function (val) {
                  switch (val) {
                    case null:
                      return "text-warning"
                    case "PD":
                      return "text-warning"
                    case "EM":
                      return "text-primary"
                    case "FA":
                      return "text-danger"
                    default:
                      return ""
                  }
                },
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "actions",
                  fn: function (actions) {
                    return [
                      _vm.canDispatchCertifiedUser(actions.row)
                        ? _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "certified_emit.emit",
                                expression: "`certified_emit.emit`",
                              },
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "certified_emit.certified_despatch"
                                ),
                                expression:
                                  "$t('certified_emit.certified_despatch')",
                              },
                            ],
                            staticClass:
                              "h-5 w-5 mb-1 mr-1 stroke-current text-primary",
                            attrs: { icon: "CheckSquareIcon" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmDispatchUser(actions.row)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.canDownloadCertifiedUser(actions.row)
                        ? _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "certified_emit.download",
                                expression: "`certified_emit.download`",
                              },
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t("baixar"),
                                expression: "$t('baixar')",
                              },
                            ],
                            staticClass:
                              "h-5 w-5 mb-1 mr-1 stroke-current text-primary",
                            attrs: { icon: "DownloadIcon" },
                            on: {
                              click: function ($event) {
                                return _vm.downloadCertifiedUser(actions.row)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.canSendEmailCertifiedUser(actions.row)
                        ? _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "certified_emit.sendemail",
                                expression: "`certified_emit.sendemail`",
                              },
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.$t(
                                  "certified_emit.certified_send_email"
                                ),
                                expression:
                                  "$t('certified_emit.certified_send_email')",
                              },
                            ],
                            staticClass:
                              "h-5 w-5 mb-1 mr-1 stroke-current text-primary",
                            attrs: { icon: "SendIcon" },
                            on: {
                              click: function ($event) {
                                return _vm.sendEmailCertifiedUser(actions.row)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              1537262007
            ),
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "w-full flex gap-2 pt-2 justify-end" },
        [
          _c(
            "vs-button",
            {
              directives: [
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: "certified_emit.emit",
                  expression: "'certified_emit.emit'",
                },
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("certified_emit.certified_despatch_all"),
                  expression: "$t('certified_emit.certified_despatch_all')",
                },
              ],
              attrs: { icon: "send" },
              on: { click: _vm.confirmDispatchAll },
            },
            [_vm._v(" " + _vm._s(_vm.$t("certified_emit.emit")) + " ")]
          ),
          _c(
            "vs-button",
            {
              attrs: { type: "border", icon: "undo" },
              on: { click: _vm.goBack },
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }